import { default as acceptable_45usexNgbTooxToMeta } from "/builds/teamtilt/web/tilt-web/pages/acceptable-use.vue?macro=true";
import { default as cookieswdAWFzTwEvMeta } from "/builds/teamtilt/web/tilt-web/pages/cookies.vue?macro=true";
import { default as indexQeni4aLFgWMeta } from "/builds/teamtilt/web/tilt-web/pages/index.vue?macro=true";
import { default as privacybT6ULTxN63Meta } from "/builds/teamtilt/web/tilt-web/pages/privacy.vue?macro=true";
import { default as _91id_937zISaM4N9KMeta } from "/builds/teamtilt/web/tilt-web/pages/rooms/[id].vue?macro=true";
import { default as applyX3jsI3w0wUMeta } from "/builds/teamtilt/web/tilt-web/pages/sellers/apply.vue?macro=true";
import { default as indexPdxH4cGhwtMeta } from "/builds/teamtilt/web/tilt-web/pages/sellers/index.vue?macro=true";
import { default as termsnyRFOgzwd1Meta } from "/builds/teamtilt/web/tilt-web/pages/terms.vue?macro=true";
export default [
  {
    name: acceptable_45usexNgbTooxToMeta?.name ?? "acceptable-use",
    path: acceptable_45usexNgbTooxToMeta?.path ?? "/acceptable-use",
    meta: acceptable_45usexNgbTooxToMeta || {},
    alias: acceptable_45usexNgbTooxToMeta?.alias || [],
    redirect: acceptable_45usexNgbTooxToMeta?.redirect || undefined,
    component: () => import("/builds/teamtilt/web/tilt-web/pages/acceptable-use.vue").then(m => m.default || m)
  },
  {
    name: cookieswdAWFzTwEvMeta?.name ?? "cookies",
    path: cookieswdAWFzTwEvMeta?.path ?? "/cookies",
    meta: cookieswdAWFzTwEvMeta || {},
    alias: cookieswdAWFzTwEvMeta?.alias || [],
    redirect: cookieswdAWFzTwEvMeta?.redirect || undefined,
    component: () => import("/builds/teamtilt/web/tilt-web/pages/cookies.vue").then(m => m.default || m)
  },
  {
    name: indexQeni4aLFgWMeta?.name ?? "index",
    path: indexQeni4aLFgWMeta?.path ?? "/",
    meta: indexQeni4aLFgWMeta || {},
    alias: indexQeni4aLFgWMeta?.alias || [],
    redirect: indexQeni4aLFgWMeta?.redirect || undefined,
    component: () => import("/builds/teamtilt/web/tilt-web/pages/index.vue").then(m => m.default || m)
  },
  {
    name: privacybT6ULTxN63Meta?.name ?? "privacy",
    path: privacybT6ULTxN63Meta?.path ?? "/privacy",
    meta: privacybT6ULTxN63Meta || {},
    alias: privacybT6ULTxN63Meta?.alias || [],
    redirect: privacybT6ULTxN63Meta?.redirect || undefined,
    component: () => import("/builds/teamtilt/web/tilt-web/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: _91id_937zISaM4N9KMeta?.name ?? "rooms-id",
    path: _91id_937zISaM4N9KMeta?.path ?? "/rooms/:id()",
    meta: _91id_937zISaM4N9KMeta || {},
    alias: _91id_937zISaM4N9KMeta?.alias || [],
    redirect: _91id_937zISaM4N9KMeta?.redirect || undefined,
    component: () => import("/builds/teamtilt/web/tilt-web/pages/rooms/[id].vue").then(m => m.default || m)
  },
  {
    name: applyX3jsI3w0wUMeta?.name ?? "sellers-apply",
    path: applyX3jsI3w0wUMeta?.path ?? "/sellers/apply",
    meta: applyX3jsI3w0wUMeta || {},
    alias: applyX3jsI3w0wUMeta?.alias || [],
    redirect: applyX3jsI3w0wUMeta?.redirect || undefined,
    component: () => import("/builds/teamtilt/web/tilt-web/pages/sellers/apply.vue").then(m => m.default || m)
  },
  {
    name: indexPdxH4cGhwtMeta?.name ?? "sellers",
    path: indexPdxH4cGhwtMeta?.path ?? "/sellers",
    meta: indexPdxH4cGhwtMeta || {},
    alias: indexPdxH4cGhwtMeta?.alias || [],
    redirect: indexPdxH4cGhwtMeta?.redirect || undefined,
    component: () => import("/builds/teamtilt/web/tilt-web/pages/sellers/index.vue").then(m => m.default || m)
  },
  {
    name: termsnyRFOgzwd1Meta?.name ?? "terms",
    path: termsnyRFOgzwd1Meta?.path ?? "/terms",
    meta: termsnyRFOgzwd1Meta || {},
    alias: termsnyRFOgzwd1Meta?.alias || [],
    redirect: termsnyRFOgzwd1Meta?.redirect || undefined,
    component: () => import("/builds/teamtilt/web/tilt-web/pages/terms.vue").then(m => m.default || m)
  }
]