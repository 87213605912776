type PinnedProductState = {
  id: string
};

export const usePinnedProduct = defineStore({
  id: 'pinnedProduct',
  state: () => ({
    id: '',
  } as PinnedProductState),
  getters: {
  },
  actions: {
  },
});
