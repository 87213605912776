import { defineNuxtPlugin } from '#app';
import { Amplify } from '@aws-amplify/core';
import { Auth } from '@aws-amplify/auth';
import { Analytics } from '@aws-amplify/analytics';

export default defineNuxtPlugin(() => {
  const config = useRuntimeConfig();
  // Amplify.Logger.LOG_LEVEL = 'DEBUG';

  Amplify.configure({
    Auth: {
      region: config.public.amplifyAuthRegion,
      userPoolId: config.public.amplifyAuthUserPoolId,
      userPoolWebClientId: config.public.amplifyAuthUserPoolWebClientId,
      identityPoolId: config.public.amplifyAuthIdentityPoolId,
      identityPoolRegion: config.public.amplifyAuthIdentityPoolRegion,
    },
    Analytics: {
      AWSPinpoint: {
        appId: config.public.amplifyAnalyticsAppId,
        region: config.public.amplifyAnalyticsRegion,
        mandatorySignIn: false,
      },
    },
    ssr: true,
  });

  return {
    provide: {
      auth: Auth,
      analytics: Analytics,
    },
  };
});