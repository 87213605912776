import validate from "/builds/teamtilt/web/tilt-web/node_modules/nuxt/dist/pages/runtime/validate.js";
import _404_45global from "/builds/teamtilt/web/tilt-web/middleware/404.global.ts";
export const globalMiddleware = [
  validate,
  _404_45global
]
export const namedMiddleware = {
  product: () => import("/builds/teamtilt/web/tilt-web/middleware/product.ts"),
  room: () => import("/builds/teamtilt/web/tilt-web/middleware/room.ts"),
  seller: () => import("/builds/teamtilt/web/tilt-web/middleware/seller.ts")
}