import Toast, { POSITION, TYPE, type PluginOptions } from 'vue-toastification';
import 'vue-toastification/dist/index.css';
import '~~/assets/styles/_toast.scss';
import IconInfo from '~~/components/toast-icons/IconInfo.vue';
import IconSuccess from '~~/components/toast-icons/IconSuccess.vue';
import IconError from '~~/components/toast-icons/IconError.vue';

const options: PluginOptions = {
  toastClassName: 'tilt-toast',
  maxToasts: 1,
  position: POSITION.TOP_CENTER,
  hideProgressBar: true,
  timeout: 4000,
  transition: 'toast',
  showCloseButtonOnHover: true,
  toastDefaults: {
    [TYPE.INFO]: {
      icon: IconInfo,
    },
    [TYPE.SUCCESS]: {
      icon: IconSuccess,
    },
    [TYPE.ERROR]: {
      icon: IconError,
    },
  },
};

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.use(Toast, options);
});
