<script setup lang="ts">
import { computed } from 'vue';

export type ButtonColor = 'primary' | 'secondary' | 'danger' | 'transparent' | 'black';
export type ButtonVariant = 'default' | 'inverted' | 'box' | 'blur';
export type ButtonSize = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

interface ButtonProps {
  text?: string
  loading?: boolean
  disabled?: boolean
  icon?: string
  iconAlt?: string
  iconPrepend?: boolean
  fullWidth?: boolean
  type?: 'button' | 'submit' | 'reset'
  color?: ButtonColor
  variant?: ButtonVariant
  size?: ButtonSize
  badge?: number | string
}

const props = withDefaults(defineProps<ButtonProps>(), {
  type: 'button',
  color: 'primary',
  variant: 'default',
  size: 'lg',
});

const loaderSize = computed(() => {
  switch (props.size) {
    case 'xl':
    case 'lg':
      return '30';
    case 'md':
      return '25';
    case 'sm':
    case 'xs':
      return '20';
    default:
      return '30';
  }
});
</script>

<template>
  <button
    :type="type"
    class="button"
    :class="[`button--${color} button--${variant} button--${size}`, { 'button--loading': loading, round: !text, 'full-width': fullWidth }]"
    :disabled="disabled"
  >
    <slot name="icon-preprend">
      <i v-if="icon && iconPrepend" class="button__icon prepend flex items-center justify-center text-neutral-5" :class="`ph-bold ph-${icon}`"/>
    </slot>
    <slot name="text">
      <span class="button__text">{{text}}</span>
    </slot>
    <i v-if="icon && !iconPrepend" class="button__icon append text-neutral-5" :class="`ph-bold ph-${icon}`"/>
    <LoadingSpinner v-if="loading" class="button__loader" state="LOADING" :width="loaderSize" :height="loaderSize"/>

    <div class="absolute left-5 top-5 w-5 h-5 bg-violette-5 rounded-full caption font-mono font-bold flex justify-center items-center" v-if="badge">
      {{badge}}</div>
  </button>
</template>

<style scoped lang="scss">
$hover-lighten-percent: 10%;
$violette-5: #9840FF;
$neutral-2: #363636;
$neutral-3: #454545;
$neutral-5: #FAFAFA;
$neutral-black: #050505;
$red-5: #FF3B51;

.button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  @apply bg-neutral-5;
  border-radius: 50px;
  border: none;
  outline: none;
  font-weight: 700;
  line-height: 24px;
  cursor: pointer;
  position: relative;
  & .button__icon {
    &.prepend {
      margin-right: 5px;
    }
    &.append {
      margin-left: 5px;
    }
  }
  &.round {
    & .button__icon {
      display: flex;
      margin-left: 0;
    }
  }
  &.full-width {
    width: 100%;
  }
  &:disabled {
    opacity: 0.4;
    cursor: default;
  }
  // colors
  &--primary {
    @apply bg-violette-5;
    &:hover {
      background-color: lighten($violette-5, $hover-lighten-percent);
    }
  }
  &--secondary {
    @apply bg-neutral-3;
    &:hover {
      background-color: lighten($neutral-3, $hover-lighten-percent);
    }
  }
  &--danger {
    @apply bg-red-5;
    &:hover {
      background-color: lighten($red-5, $hover-lighten-percent);
    }
  }
  &--transparent {
    background-color: transparent;
    &:hover {
      @apply bg-neutral-2;
    }
  }
  &--black {
    @apply bg-neutral-black;
    &:hover {
      background-color: lighten($neutral-black, $hover-lighten-percent);
    }
  }
  // variants
  &--inverted {
    @apply bg-neutral-3;
    &:hover {
      background-color: lighten($neutral-3, $hover-lighten-percent);
    }
    &.button--primary {
      @apply bg-violette-5;
    }
    &.button--danger {
      @apply text-red-5;
    }
  }
  &--box {
    @apply bg-neutral-5;
    font-size: 15px !important;
    padding: 24px !important;
    background: rgba($neutral-2, 0.52);
    border-radius: 12px;

    &:hover {
      background-color: lighten(rgba($neutral-2, 0.52), $hover-lighten-percent);
    }
  }
  &--blur {
    background: rgba(33, 32, 34, 0.76);
    backdrop-filter: blur(24px);
    &:hover {
      background-color: rgba(33, 32, 34, 0.66);
    }
  }
  // sizes
  &--xl {
    padding: 16px 24px;
    font-size: 17px;
    &.round {
      padding: 18.63px;
    }
    & .button__icon {
      @apply text-3xl;
    }
  }
  &--lg {
    padding: 12px 24px;
    font-size: 17px;
    &.round {
      padding: 14.63px;
    }
    & .button__icon {
      @apply text-2xl;
    }
  }
  &--md {
    @apply px-6 py-2 text-base;
    &.round {
      padding: 10px;
    }
    & .button__icon {
      @apply text-xl leading-initial;
    }
    & .button__text {
      @apply body-2;
    }
  }
  &--sm {
    padding: 4px 12px;
    font-size: 13px;
    &.round {
      padding: 6px;
    }
    & .button__icon {
      @apply text-base;
    }
  }
  &--xs {
    padding: 2px 8px;
    font-size: 13px;
    line-height: 20px;
    &.round {
      padding: 5.75px;
      & .button__icon {
        @apply text-xs;
      }
    }
  }
  &--loading {
    @apply bg-neutral-3;
    cursor: default;
    &:hover {
      @apply bg-neutral-3;
    }
    & .button__text,
    & .button__icon {
      visibility: hidden;
      opacity: 0;
    }

    & .button__loader {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
</style>
