<script setup lang="ts">
import lottie from 'lottie-web';
import loaderSpinningAnimation from '~~/assets/lottie/loader-spinning.json';
import loaderSuccessAnimation from '~~/assets/lottie/loader-success.json';
import loaderErrorAnimation from '~~/assets/lottie/loader-error.json';

export type LoaderState = 'LOADING' | 'SUCCESS' | 'ERROR' | 'DISABLED';

interface Props {
  state: LoaderState,
  width: string
  height: string
}

const props = withDefaults(defineProps<Props>(), {
  state: 'DISABLED',
  width: '20',
  height: '20',
});

const player = ref<HTMLElement | null>(null);

onMounted(() => {
  player.value?.addEventListener('rendered', () => {
    loadAnimation(props.state);
  });
});

const loadAnimation = (state: LoaderState) => {
  let animation = null;
  let loop = false;
  if (state === 'LOADING') {
    animation = loaderSpinningAnimation;
    loop = true;
  } else if (state === 'SUCCESS') {
    animation = loaderSuccessAnimation;
  } else if (state === 'ERROR') {
    animation = loaderErrorAnimation;
  } else {
    return stopAnimation();
  }
  lottie.loadAnimation({
    container: player.value,
    renderer: 'svg',
    autoplay: true,
    path: animation,
    loop,
  });
  // lottie.play();
};

const stopAnimation = () => {
  lottie.stop();
};

watch(() => props.state, (newVal) => {
  loadAnimation(newVal);
});
</script>

<template>
  <div
    ref="player"
    v-show="state !== 'DISABLED'"
    :style="{ width: `${width}px`, height: `${height}px` }"
  ></div>
</template>
