export const appCdnURL = "<NUXT APP CDN URL>"

export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1, maximum-scale=1"},{"charset":"utf-8"},{"name":"description","content":"Discover emerging fashion, unlock rare collectibles, and shop through videos + live streams with friends."}],"link":[{"rel":"manifest","href":"/site.webmanifest"},{"rel":"icon","type":"image/x-icon","href":"/favicon.ico"},{"rel":"icon","type":"image/png","sizes":"16x16","href":"/favicon-16x16.png"},{"rel":"icon","type":"image/png","sizes":"32x32","href":"/favicon-32x32.png"},{"rel":"apple-touch-icon","sizes":"180x180","href":"/apple-touch-icon.png"},{"rel":"stylesheet","type":"text/css","href":"https://unpkg.com/@phosphor-icons/web@2.0.3/src/bold/style.css"},{"rel":"stylesheet","type":"text/css","href":"https://unpkg.com/@phosphor-icons/web@2.0.3/src/fill/style.css"}],"style":[],"script":[{"src":"/js/polyfills.js"},{"src":"/js/meta-pixel.js","type":"text/javascript"},{"src":"/js/tiktok-pixel.js","type":"text/javascript"}],"noscript":[],"title":"Tilt","charset":"utf-8","viewport":"width=device-width, initial-scale=1, maximum-scale=1"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = false

export const devPagesDir = null