export default defineNuxtRouteMiddleware((to) => {
  const config = useRuntimeConfig();

  if (!to.matched.length) {
    return navigateTo(config.baseUrl, {
      redirectCode: 301,
      replace: true,
      external: true,
    });
  }
});
