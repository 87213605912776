type LiveState = {
  state?: 'TEASER_VIDEO' | 'ACTIVE' | 'HOST_LEFT' | 'LIVE_ENDED'
};

export const useLiveState = defineStore({
  id: 'liveState',
  state: () => ({
    state: undefined,
  } as LiveState),
  getters: {
  },
  actions: {
  },
});
