import { Channel, StreamChat, Event as StreamEvent, type QueryChannelAPIResponse, type OwnUserResponse, MessageResponse } from 'stream-chat';
import { usePinnedProduct } from './pinned-product';
import { useLiveState } from './live-state';
import { useThreeMoji } from './threemoji';

export type Message = MessageResponse & {
  data?: {
    systemMessageType?: string
    reactions?: string[]
    productId?: string
  }
};

type ChatState = {
  client: StreamChat | null,
  channel: Channel | null
  messages: Message[]
  pinnedMessage: Message | null,
  state: QueryChannelAPIResponse | null
  guestUser: OwnUserResponse | null
  viewerCount: number
};

export const useChat = defineStore({
  id: 'chat',
  state: () => ({
    client: null,
    channel: null,
    messages: [],
    pinnedMessage: null,
    state: null,
    guestUser: null,
    viewerCount: 0,
  } as ChatState),
  getters: {
  },
  actions: {
    initClient() {
      if (!!this.client) return;
      const { public: { streamChatApiKey }} = useRuntimeConfig();
      this.client = StreamChat.getInstance(streamChatApiKey);
    },
    async connect(roomId: string, guestUserId: string) {
      if (!this.client) this.initClient();
      await this.client.setGuestUser({ id: guestUserId });
      this.channel = this.client.channel('livestream', roomId);
      this.state = await this.channel.watch();
      this.messages = this.state.messages;
      this.viewerCount = this.state.watcher_count;
      if (this.state?.pinned_messages.length) {
        this.pinnedMessage = this.state.pinned_messages[0];
      }

      this.addChannelEventListeners();
    },
    async disconnect() {
      this.removeChannelEventListeners();
      await this.channel.stopWatching();
      await this.client.disconnectUser();
      this.channel = null;
      this.messages = [];
      this.viewerCount = 0;
      this.pinnedMessage = null;
    },
    updateWatcherCount(event: StreamEvent) {
      if (event.watcher_count) {
        this.viewerCount = event.watcher_count;
      }
      if (event.type === 'user.watching.start' && event.user.role === 'user') {
        this.messages.push({
          data:  {
            systemMessageType: 'USER_JOINED',
          },
          user: event.user,
          text: 'joined',
        } as Message);
      }
    },
    update3Mojis(event: StreamEvent) {
      console.log('3mojis_updated', event);
      // live.threemoji = event.emojis as string[];

      // @TODO: remove following block when BE starts sending events only when 3moji has changed
      if (event.type === '3mojis_updated') {
        for (let i = 0; i < 3; i += 1) {
          if (event.emojis[i] !== live.threemoji[i]) {
            live.threemoji = event.emojis as string[];
            break;
          }
        }
      }
    },
    handleNewMessage(event: StreamEvent & { message: Message }) { // eslint-disable-line
      if (!event.message) return;
      if (event.message.data?.systemMessageType === 'LIVE_ENDED') {
        const liveState = useLiveState();
        liveState.state = 'LIVE_ENDED';
        // const { $leaveLive } = useNuxtApp();
        // $leaveLive();
      }
      if (!['REACTION_ADDED', 'THREE_MOJIS_UPDATED'].includes(event.message.data?.systemMessageType)) {
        this.messages.push(event.message);
      }
      if (event.message.data?.systemMessageType === 'CHANGED_FEATURED_PRODUCT') {
        const pinnedProduct = usePinnedProduct();
        pinnedProduct.id = event.message.data?.productId;
      }
      if (event.message.data?.systemMessageType === 'THREE_MOJIS_UPDATED') {
        const threemoji = useThreeMoji();
        const reactions = event.message.data?.reactions;
        if (reactions.length > threemoji.threemoji.length || reactions.length < threemoji.threemoji.length) {
          return threemoji.populateFromStream(reactions);
        }
        for (let i = 0; i < reactions.length; i += 1) {
          if (reactions[i] !== threemoji.threemoji[i].reaction) {
            threemoji.populateFromStream(reactions);
            break;
          }
        }
      }
    },
    updatePinnedMessage(event: StreamEvent) {
      if (!event.message) return;
      if (event.message.pinned) {
        this.pinnedMessage = event.message;
      } else {
        this.pinnedMessage = null;
      }
    },
    async addChannelEventListeners() {
      this.channel?.on('user.watching.start', this.updateWatcherCount);
      this.channel?.on('user.watching.stop', this.updateWatcherCount);
      this.channel?.on('message.new', this.handleNewMessage);
      this.channel?.on('message.updated', this.updatePinnedMessage);
      this.channel?.on('3mojis_updated', this.update3Mojis);
    },
    removeChannelEventListeners() {
      this.channel?.off('user.watching.start', this.updateWatcherCount);
      this.channel?.off('user.watching.stop', this.updateWatcherCount);
      this.channel?.off('message.new', this.handleNewMessage);
      this.channel?.off('message.updated', this.updatePinnedMessage);
      this.channel?.off('3mojis_updated', this.update3Mojis);
    },
  },
});
