import { defineNuxtPlugin } from '#app';
import AgoraRTC, { IAgoraRTCRemoteUser, IRemoteAudioTrack } from 'agora-rtc-sdk-ng';
import { useLiveState } from '~~/stores/live-state';

AgoraRTC.setLogLevel(3);
const client = AgoraRTC.createClient({ mode: 'live', codec: 'vp8' });
export type MediaType = 'audio' | 'video';
let audioTrack: IRemoteAudioTrack;

const handleUserPublished = async (user: IAgoraRTCRemoteUser, mediaType: MediaType) => {
  const uid = typeof user.uid === 'string' ? parseInt(user.uid) : user.uid;
  if (uid >= 1000 && uid < 1005) return; // ignore system users

  await client.subscribe(user, mediaType);
  const liveState = useLiveState();
  liveState.state = 'ACTIVE';
  if (mediaType === 'video') {
    user.videoTrack?.play('live-video-container', { fit: 'cover' });
  }
  if (mediaType === 'audio' && user.audioTrack) {
    audioTrack = user.audioTrack;
  }
};

const handleUserUnpublished = async (user: IAgoraRTCRemoteUser, mediaType: MediaType) => {
  const uid = typeof user.uid === 'string' ? parseInt(user.uid) : user.uid;
  if (uid >= 1000 && uid < 1005) return; // ignore system users

  if (mediaType === 'video') {
    const liveState = useLiveState();
    if (liveState.state !== 'LIVE_ENDED') {
      liveState.state = 'HOST_LEFT';
    }
  }
};

const joinLive = async (roomId: string, streamKey: string, guestUserId: string) => {
  const { public: { agoraAppId } } = useRuntimeConfig();
  client.setClientRole('audience');
  client.on('user-published', handleUserPublished);
  client.on('user-unpublished', handleUserUnpublished);
  await client.join(agoraAppId, roomId, streamKey, guestUserId);
};

const leaveLive = async () => {
  const playerContainer = document.querySelector('#live-video-container video');
  playerContainer?.remove();
  await client.leave();
};

const toggleMute = () => {
  if (!audioTrack) return;
  if (audioTrack.isPlaying) {
    audioTrack.stop();
  } else {
    audioTrack.play();
  }
  return audioTrack.isPlaying;
};

export default defineNuxtPlugin(() => {
  return {
    provide: {
      joinLive,
      toggleMute,
      leaveLive,
    },
  };
});