import { TopReaction } from '@teamtilt/social-api';

type ThreemojiState = {
  threemoji: TopReaction[]
};

export const useThreeMoji = defineStore({
  id: 'threemoji',
  state: () => ({
    threemoji: [],
  } as ThreemojiState),
  getters: {
  },
  actions: {
    populateFromStream(reactions: string[]) {
      const threemoji = [];
      reactions.forEach((reaction: string) => {
        threemoji.push({
          reaction,
          number: 0,
        });
      });
      this.threemoji = threemoji;
    },
  },
});
