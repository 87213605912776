import VueGtag, { trackRouter } from 'vue-gtag-next';

export default defineNuxtPlugin((nuxtApp) => {
  const consent = JSON.parse(localStorage.getItem('consent'));

  nuxtApp.vueApp.use(VueGtag, {
    isEnabled: consent,
    property: {
      id: 'UA-179474556-1',
    },
  }),
  trackRouter(useRouter());
});